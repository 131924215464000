import "./styles.css"

export default function() {
  return (
    <div className="Footer">
       <div className="credits">
         2020 - 2023 <a href="https://github.com/MRSS02"
         className="gh-link">MRSS02</a>
       </div>
    </div>
  )
}
